import React from "react";
// import { useMediaQuery } from "react-responsive";

import logoGoldenWeeksHeader from "./logoGoldenWeeksHeaderCrop.png";

import "./App.css";
// import TopNavbar from "./Components/TopNavBar";
// import { Content } from "./Components/Content";

import "bootstrap/dist/css/bootstrap.min.css";
import { NoBookingContent } from "./Components/NoBookingContent";

const App = () => {
  // const isDesktopOrLaptop = useMediaQuery({
  //   query: "(min-width: 1400px)",
  // });

  if (window.location.pathname === "/confirmation") {
    return (
      <div className="App">
        <header className="App-header">
          <div>
            <div className="logo-div">
              <img
                id="home"
                src={logoGoldenWeeksHeader}
                className="GoldenWeeks-logo"
                alt="logo"
              />
            </div>
            <p>Thanks for reaching out, I'll be in touch.</p>
          </div>
        </header>
      </div>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <div>
          {/* {!isDesktopOrLaptop && <TopNavbar />} */}
          <div className="logo-div">
            <img
              id="home"
              src={logoGoldenWeeksHeader}
              className="GoldenWeeks-logo"
              alt="logo"
            />
          </div>
          {/* {isDesktopOrLaptop && <TopNavbar />} */}

          <NoBookingContent />
          {/* <Content /> */}
        </div>
      </header>
    </div>
  );
};

export default App;
