import "./NoBookingContent.css";

export const NoBookingContent = () => {
    return (
        <div className="content-div">
            <div className="text-container">
                <p>
                    Thanks for your interest. I am no longer taking any further bookings for this year but will update this site when I am taking bookings again.
                </p>
            </div>
        </div>
    )
};